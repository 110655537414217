import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Onboarding as a Data Scientist`}</h1>
    <p>{`Thank you so much for your interest in helping out!`}</p>
    <p>{`There are many ways you can contribute your skills as a data scientist.
This page will describe some of this in further detail.`}</p>
    <h2>{`Work with an independent news organization`}</h2>
    <p>{`Independent `}<a parentName="p" {...{
        "href": "/newsrooms",
        "title": "newsrooms"
      }}>{`newsrooms`}</a>{` are `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      